body,
button {
  /* font-family: "Source Sans Pro", sans-serif; */
  /* font-family: 'Open Sans', sans-serif; */
  /* font-family: 'Comic Neue', cursive; */
  font-family: "Roboto", sans-serif;
}

* {
  font-size: 14px;
  font-weight: 400;
}

b,
strong {
  font-weight: 500;
}

svg {
    float: left;
    color: #3498db;
    margin: 3px 6px 0px 0px;
    font-size: 1rem !important;
}

p {
  margin: 0;
  text-align: left;
  line-height: 1.5rem;
}

.server strong {
  color: #c23616;
  font-weight: 400;
}

a {
  cursor: pointer;
  color: #0097e6;
  opacity: .85;
}
